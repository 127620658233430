import { AlertColor } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit';

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    visible: false,
    text: '',
    severity: 'success' as AlertColor,
  },
  reducers: {
    successAlert: (state, action) => {
      state.visible = true
      state.text = action.payload
      state.severity = 'success'
    },
    errorAlert: (state, action) => {
      state.visible = true
      state.text = action.payload
      state.severity = 'error'
    },
    hideAlert: state => {
      state.visible = false
    },
  }
})

export const { successAlert, errorAlert, hideAlert } = loadingSlice.actions;
export default loadingSlice.reducer;
