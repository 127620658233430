import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from 'config';
import gather from 'tools/gather';

export const getAssets = createAsyncThunk('getAssets', async () => {
  return await gather(`${config.apiUrl}/v1/coin`).get();
});

export const getData = createAsyncThunk('getData', async () => {
  return await gather(`${config.apiUrl}/v1/data`).get();
});

export const getMarket = createAsyncThunk('getMarket', async () => {
  return await gather(`${config.apiUrl}/v1/market`).get();
});

export const getEnergy = createAsyncThunk('getEnergy', async () => {
  return await gather(`${config.apiUrl}/v1/power`).get();
});

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    assets: [] as any,
    data: {} as any,
    market: [] as any,
    energy: [] as any,
  },
  reducers: {
    updateMarket: (state, action) => { 
      const index = state.market.findIndex((e: any) => e.symbol === action.payload.symbol);
      if (index >= 0) {
        state.market[index] = action.payload; 
      } else {
        state.market.push(action.payload);
      }
    },
  }, extraReducers: (builder) => {
    builder
      .addCase(getAssets.fulfilled, (state, action) => {
        state.assets = action.payload.data.map((obj: any) => { 
          return {
            ...obj, 
            icon: 'icons/' + obj.symbol.toLowerCase() + '.png',
          }
        });
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
      })
      .addCase(getMarket.fulfilled, (state, action) => {
        state.market = action.payload.data;
      })
      .addCase(getEnergy.fulfilled, (state, action) => {
        state.energy = action.payload.data;
      });
  }
})

export const { updateMarket } = loadingSlice.actions;
export default loadingSlice.reducer;
