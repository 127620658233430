import { Alert, Backdrop, CircularProgress, Drawer, Snackbar } from '@mui/material';
import config from 'config';
import Cookies from 'js-cookie';
import i18n from 'lang';
import Coin from 'pages/Coin';
import Energy from 'pages/Energy';
import Fast from 'pages/Fast';
import Market from 'pages/Market';
import Odds from 'pages/Odds';
import Setting from 'pages/Setting';
import Splash from 'pages/Splash';
import Swap from 'pages/Swap';
import Wallet from 'pages/Wallet';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'reducer';
import { hideAlert } from 'reducer/slices/alertSlice';
import { getAssets, getData, getEnergy, getMarket } from 'reducer/slices/dataSlice';
import { hideDrawer, hideDrawer2 } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { getProfile, getWallet } from 'reducer/slices/userSlice';
import socket from 'socket';
import gather from 'tools/gather';

const App = () => {
  const dispatch = useAppDispatch();
  const [isLogin, setIsLogin] = useState(false);

  const useLoading = useAppSelector((state) => state.loading);
  const useDrawer = useAppSelector((state) => state.drawer);
  const useAlert = useAppSelector((state) => state.alert);

  const checkLanguage = async () => {
    const lang = Cookies.get('language') ?? 'en';
    i18n.changeLanguage(lang);
    document.documentElement.lang = i18n.language;
  };

  const init = async () => {
    checkLanguage();

    dispatch(showLoading());

    await Promise.all([dispatch(getData()), dispatch(getAssets()), dispatch(getMarket()), dispatch(getEnergy())]);

    const refreshToken = Cookies.get('refreshToken');
    if (refreshToken) {
      const result = await gather(`${config.apiUrl}/v1/auth/refreshToken`).get({
        authorization: `Bearer ${refreshToken}`,
      });

      if (result.code === 200) {
        Cookies.set('accessToken', result.data.accessToken, { expires: 365 });

        await Promise.all([dispatch(getProfile()), dispatch(getWallet())]);

        socket.connect(dispatch);

        setIsLogin(true);
      }
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <BrowserRouter basename='/'>
      <div className='app container'>
        <Drawer PaperProps={{ sx: { height: '100%' } }} anchor={useDrawer.position} open={useDrawer.visible} onClose={() => dispatch(hideDrawer())}>
          {useDrawer.content}
        </Drawer>
        <Drawer PaperProps={{ sx: { height: '100%' } }} anchor={useDrawer.position2} open={useDrawer.visible2} onClose={() => dispatch(hideDrawer2())}>
          {useDrawer.content2}
        </Drawer>
        <Backdrop sx={{ backgroundColor: 'rgba(255, 255, 255, 0.95)', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }} open={useLoading.visible}>
          <CircularProgress color='info' />
        </Backdrop>
        <Snackbar
          open={useAlert.visible}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          autoHideDuration={1500}
          onClose={() => dispatch(hideAlert())}
        >
          <Alert onClose={() => dispatch(hideAlert())} variant='filled' severity={useAlert.severity} sx={{ width: '100%', mb: '10px' }}>
            {useAlert.text}
          </Alert>
        </Snackbar>
        {!isLogin ? (
          <Splash />
        ) : (
          <Routes>
            <Route path='/' element={<Wallet />} />
            <Route path='/setting' element={<Setting />} />
            <Route path='/fast' element={<Fast />} />
            <Route path='/market' element={<Market />} />
            <Route path='/coin' element={<Coin />} />
            <Route path='/energy' element={<Energy />} />
            <Route path='/swap' element={<Swap />} />
            <Route path='/odds' element={<Odds />} />
          </Routes>
        )}
      </div>
    </BrowserRouter>
  );
};

export default App;
