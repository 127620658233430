import { createSlice } from '@reduxjs/toolkit';

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    visible: false,
    content: null,
    position: 'bottom' as any,
    entry: 'bottom',
    visible2: false,
    content2: null,
    position2: 'bottom' as any,
    entry2: 'bottom',
  },
  reducers: {
    bottomDrawer: (state, action) => {
      state.visible = true;
      state.content = action.payload;
      state.entry = 'bottom';
      state.position = 'bottom';
    },
    bottomDrawer2: (state, action) => {
      state.visible2 = true;
      state.content2 = action.payload;
      state.entry2 = 'bottom';
      state.position2 = 'bottom';
    },
    topDrawer: (state, action) => {
      state.visible = true;
      state.content = action.payload;
      state.entry = 'top';
      state.position = 'top';
    },
    hideDrawer: (state) => {
      state.visible = false;
    },
    hideDrawer2: (state) => {
      state.visible2 = false;
    },
  },
});

export const { bottomDrawer, bottomDrawer2, topDrawer, hideDrawer, hideDrawer2 } = drawerSlice.actions;
export default drawerSlice.reducer;
