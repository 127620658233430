import Swap from 'components/Swap';
import config from 'config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'reducer';
import { bottomDrawer, hideDrawer } from 'reducer/slices/drawerSlice';
import utils from 'tools/utils';

let before: any;

const Bottom = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const useData = useAppSelector((state) => state.data);
  const useUser = useAppSelector((state) => state.user);
  const [balanceShow, setBalanceShow] = useState(true);

  const openPopup = (e: any, second: string) => {
    dispatch(hideDrawer());

    setTimeout(() => {
      if (second === 'beforeSwap') {
        before = e;
        dispatch(bottomDrawer(listAsset('', 'swap')));
      } else if (second === 'swap') {
        dispatch(bottomDrawer(<Swap data={before} to={e} />));
      }
    }, 500);
  };

  const listAsset = (first: string = '', second: string = '') => {
    let filter = first ? useData.assets.filter((e: any) => e[first][second]) : useData.assets;

    if (second === 'beforeSwap') {
      filter = filter.filter((e: any) => e.swap.length);
    } else if (second === 'swap') {
      const beforeMap = before.swap.map((e: any) => e.coin);
      filter = filter.filter((e: any) => beforeMap.includes(e.symbol));
    }

    const rows: any = [];

    filter.forEach((e: any, i: number) => {
      const balance = useUser.wallet.find((d: any) => d.coin === e.symbol)?.free || 0;

      rows.push(
        <div key={i} className='assetSelect assetBalance' onClick={() => openPopup(e, second)}>
          <img crossOrigin='anonymous' src={`${config.apiUrl}/${e?.icon}`} className='popupImage balanceImage' />
          <div className='walletLeft'>
            <div className='walletText'>{e.symbol}</div>
            <div className='walletText walletTextName'>{e.name}</div>
          </div>
          <div className='walletRight'>
            <div className='walletText'>{balanceShow ? utils.formatter(utils.toFixed(balance, e.precision)) : '********'}</div>
            {/* <div className='walletText walletTextName'>{utils.formatter(toman)} IRR</div> */}
          </div>
        </div>,
      );
    });

    let title = null;
    if (second === 'use') {
      title = t('whichUse');
    } else if (second === 'create') {
      title = t('whichCreate');
    } else if (second === 'send') {
      title = t('whichSend');
    } else if (second === 'receive') {
      title = t('whichReceive');
    } else if (second === 'beforeSwap') {
      title = t('whichSwap');
    } else if (second === 'swap') {
      title = t('whichSwapTo');
    }

    return (
      <div className='fullPop fullPopOpa'>
        <div className='container'>
          <div style={{ padding: 30 }} onClick={() => dispatch(hideDrawer())}>
            <div className='closeIcon'></div>
          </div>
          <div className='fullPopContent'>
            <div className='selecter'>{t('selectCoin')}</div>
            {title && <div className='iWill'>{title}</div>}
            {rows}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='shortcutsParent'>
      <div className='container'>
        <div className='shortcuts'>
          <Link className={'/' === location.pathname ? 'active' : ''} to='/'>
            <i></i>
            <b>{t('wallet')}</b>
          </Link>
          <Link className={'/market' === location.pathname ? 'active' : ''} to='/market'>
            <i></i>
            <b>{t('market')}</b>
          </Link>
          {/* <Link className={'/ourCoin' === location.pathname ? 'active' : ''} to='/ourCoin'><i></i><b>OurCoin</b></Link> */}
          {/* {['ht99tr@gmail.com', 'a@a.com'].includes(useUser.profile.email) && ( */}
          <Link className={'/fast' === location.pathname ? 'active' : ''} to='/fast'>
            <i></i>
            <b>{t('fast')}</b>
          </Link>
          {/* )} */}
          <Link className={'/swap' === location.pathname ? 'active' : ''} to='/swap'>
            <i></i>
            <b>{t('swap')}</b>
          </Link>
          {/* {['ht99tr@gmail.com', 'a@a.com'].includes(useUser.profile.email) && (
            <>
              <Link className={'/odds' === location.pathname ? 'active' : ''} to='/odds'>
                <i></i>
                <b>Odds</b>
              </Link>
              <Link className={'/live' === location.pathname ? 'active' : ''} to='/live'>
                <i></i>
                <b>Live</b>
              </Link>
            </>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Bottom;
