import config from 'config';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const TokenList = () => {
  const dispatch = useAppDispatch();
  const [list, setList] = useState([]);

  const copyAddress = (address: string) => {
    navigator.clipboard.writeText(address);
    dispatch(successAlert('Copied successful'));
  };

  const activate = async (e: any) => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/token/${e.id}`, true).put({
      ...e,
      isActive: !e.isActive,
    });

    if (result.code === 200) {
      dispatch(successAlert('Your request successful'));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  };

  const showList = () => {
    const rows: any = [];

    list.forEach((e: any, i) => {
      rows.push(
        <div className='showToken' key={i}>
          <div>
            <span>{e.name}</span>
            <span>{e.value}</span>
            <span>{e.callback}</span>
          </div>
          <div>
            <span className='copy' onClick={() => copyAddress(e.value)}></span>
            <span className={`copy ${e.isActive ? 'active' : 'deactive'}`} onClick={() => activate(e)}></span>
          </div>
        </div>,
      );
    });

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/token`, true).get();
    if (result.code === 200) {
      setList(result.data);
    } else {
      dispatch(hideDrawer());
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>Token List</div>
          {/* <div className='receiveIcon receiveIconLarge sendIcon'></div> */}
        </div>
        <div className='spacerFlex'>
          <div className='form list'>{showList()}</div>
        </div>
      </div>
    </div>
  );
};

export default TokenList;
