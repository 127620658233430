import Menu from 'components/Menu';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'reducer';
import { topDrawer } from 'reducer/slices/drawerSlice';

const Header = ({ title }: any) => {
  const dispatch = useAppDispatch();
  
  return (
    <div className='topMenu'>
      <div className='topMenuLeft'>
        <h2 className='topTitle'>uWallet</h2>
        <h3 className='topTitleDetail'>{title}</h3>
      </div>
      <Link className='menu setting' to='/setting'></Link>
      <span className='menu' onClick={() => dispatch(topDrawer(<Menu />))}></span>
    </div>
  );
}

export default Header;
