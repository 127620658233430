import Airdrop from 'components/Airdrop';
import CreateVoucher from 'components/CreateVoucher';
import Receive from 'components/Receive';
import Send from 'components/Send';
import Swap from 'components/Swap';
import UseVoucher from 'components/UseVoucher';
import config from 'config';
import Cookies from 'js-cookie';
import Bottom from 'layouts/Bottom';
import Header from 'layouts/Header';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'reducer';
import { bottomDrawer, hideDrawer } from 'reducer/slices/drawerSlice';
import utils from 'tools/utils';

let before: any;

const Wallet = () => {
  const dispatch = useAppDispatch();
  const useData = useAppSelector((state) => state.data);
  const useUser = useAppSelector((state) => state.user);
  const [balanceShow, setBalanceShow] = useState(true);
  const [sort, setSort] = useState('default');
  const { t } = useTranslation();
  // console.log(useData.data.available);
  let sumBalance = 0;
  useData.assets.forEach((e: any) => {
    const balance = useUser.wallet.find((d: any) => d.coin === e.symbol)?.free || 0;
    const market = useData.market.find((d: any) => d.symbol === e.symbol)?.price || 1;
    // console.log(balance, market);
    sumBalance += balance * market;
  });

  const setUserSort = (value: string) => {
    if (value === sort) {
      setSort('default');
    } else {
      setSort(value);
    }
  };

  useEffect(() => {
    (async () => {
      setBalanceShow(Cookies.get('balanceShow') === 'true' ?? false);
    })();
  }, []);

  const changeHide = async () => {
    setBalanceShow(!balanceShow);
    Cookies.set('balanceShow', (!balanceShow).toString(), { expires: 365 });
  };

  const openForVoucher = (a: string, b: string) => {
    dispatch(hideDrawer());

    setTimeout(() => {
      dispatch(bottomDrawer(listAsset(a, b)));
    }, 300);
  };

  const voucherSelector = () => {
    return (
      <div className='fullPop fullPopOpa'>
        <div className='container'>
          <div style={{ padding: 30 }} onClick={() => dispatch(hideDrawer())}>
            <div className='closeIcon'></div>
          </div>
          <div className='fullPopContent'>
            <div className='selecter'>{t('whatDoYouWant')}</div>
            <div className='parentSelector'>
              <div className='inTabs inActiveTabs' onClick={() => openForVoucher('voucher', 'create')}>
                {t('createVoucher')}
              </div>
              <div className='inTabs inActiveTabs' onClick={() => openForVoucher('voucher', 'use')}>
                {t('useVoucher')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const cVoucherData = useData.assets.find((d: any) => d.symbol === 'cVoucher');
  const usdtData = useData.assets.find((d: any) => d.symbol === 'USDT');
  const sumBalanceCVoucher = sumBalance * (useData.market.find((d: any) => d.symbol === 'cVoucher')?.rate || 1);

  const listBalance = () => {
    const rows: any = [];

    let filter = useData.assets.slice();
    if (sort !== 'default') {
      if (sort === 'topBalance') {
        filter.sort(
          (a: any, b: any) =>
            (useUser.wallet.find((d: any) => d.coin === b.symbol)?.free || 0) - (useUser.wallet.find((d: any) => d.coin === a.symbol)?.free || 0),
        );
      } else {
        filter.sort(
          (a: any, b: any) =>
            (useUser.wallet.find((d: any) => d.coin === a.symbol)?.free || 0) - (useUser.wallet.find((d: any) => d.coin === b.symbol)?.free || 0),
        );
      }
    }

    filter.forEach((e: any, i: number) => {
      const balance = useUser.wallet.find((d: any) => d.coin === e.symbol)?.free || 0;
      const toman = useData.market.find((d: any) => d.symbol === e.symbol)?.toman || '-';

      rows.push(
        <Link key={i} className='assetSelect assetBalance' to='/coin' state={{ data: e }}>
          <img crossOrigin='anonymous' src={`${config.apiUrl}/${e?.icon}`} className='popupImage balanceImage' />
          <div className='walletLeft'>
            <div className='walletText'>{e.symbol}</div>
            <div className='walletText walletTextName'>{e.name}</div>
          </div>
          <div className='walletRight'>
            <div className='walletText'>{balanceShow ? utils.formatter(utils.toFixed(balance, e.precision)) : '********'}</div>
            <div className='walletText walletTextName'>{utils.formatter(toman)} IRR</div>
          </div>
        </Link>,
        // <div key={i}>
        //   <img crossOrigin='anonymous' src={`${config.apiUrl}/${e?.icon}`} />
        //   <span>{e.name}</span>
        //   <i>{utils.formatter(balance)} {e.symbol}</i>
        // </div>
      );
    });

    return rows;
  };

  const openPopup = (e: any, second: string) => {
    dispatch(hideDrawer());

    setTimeout(() => {
      if (second === 'receive') {
        dispatch(bottomDrawer(<Receive data={e} />));
      } else if (second === 'send') {
        dispatch(bottomDrawer(<Send data={e} />));
      } else if (second === 'use') {
        dispatch(bottomDrawer(<UseVoucher data={e} />));
      } else if (second === 'create') {
        dispatch(bottomDrawer(<CreateVoucher data={e} />));
      } else if (second === 'beforeSwap') {
        before = e;
        dispatch(bottomDrawer(listAsset('', 'swap')));
      } else if (second === 'swap') {
        dispatch(bottomDrawer(<Swap data={before} to={e} />));
      }
    }, 500);
  };

  const listAsset = (first: string = '', second: string = '') => {
    let filter = first ? useData.assets.filter((e: any) => e[first][second]) : useData.assets;

    if (second === 'beforeSwap') {
      filter = filter.filter((e: any) => e.swap.length);
    } else if (second === 'swap') {
      const beforeMap = before.swap.map((e: any) => e.coin);
      filter = filter.filter((e: any) => beforeMap.includes(e.symbol));
    }

    const rows: any = [];

    filter.forEach((e: any, i: number) => {
      const balance = useUser.wallet.find((d: any) => d.coin === e.symbol)?.free || 0;

      rows.push(
        <div key={i} className='assetSelect assetBalance' onClick={() => openPopup(e, second)}>
          <img crossOrigin='anonymous' src={`${config.apiUrl}/${e?.icon}`} className='popupImage balanceImage' />
          <div className='walletLeft'>
            <div className='walletText'>{e.symbol}</div>
            <div className='walletText walletTextName'>{e.name}</div>
          </div>
          <div className='walletRight'>
            <div className='walletText'>{balanceShow ? utils.formatter(utils.toFixed(balance, e.precision)) : '********'}</div>
            {/* <div className='walletText walletTextName'>{utils.formatter(toman)} IRR</div> */}
          </div>
        </div>,
      );
    });

    let title = null;
    if (second === 'use') {
      title = t('whichUse');
    } else if (second === 'create') {
      title = t('whichCreate');
    } else if (second === 'send') {
      title = t('whichSend');
    } else if (second === 'receive') {
      title = t('whichReceive');
    } else if (second === 'beforeSwap') {
      title = t('whichSwap');
    } else if (second === 'swap') {
      title = t('whichSwapTo');
    }

    return (
      <div className='fullPop fullPopOpa'>
        <div className='container'>
          <div style={{ padding: 30 }} onClick={() => dispatch(hideDrawer())}>
            <div className='closeIcon'></div>
          </div>
          <div className='fullPopContent'>
            <div className='selecter'>{t('selectCoin')}</div>
            {title && <div className='iWill'>{title}</div>}
            {rows}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header title='Your Wallet' />
      <div className='splashContainer'>
        <div className='walletBox'>
          <div className='walletBoxInner'>
            <div className='walletBoxIcon'></div>
            <div className='boxBalance'>
              <div className='sumBalance'>Sum Balance:</div>
              <div className='balance'>
                {balanceShow ? utils.formatter(utils.toFixed(sumBalance, usdtData.precision)) : '********'}
                <i className='balanceDollar'>$</i>
              </div>
              {/* <div className='toAnother'>
                ~{balanceShow ? utils.formatter(utils.toFixed(sumBalanceCVoucher, cVoucherData.precision)) : '********'}{' '}
                <i className='toAnotherSmall'>cVoucher</i>
              </div> */}
              {/* <div className='toAnother'>~{balanceShow ? utils.formatter(utils.toFixed(sumBalancePM, pmData.precision)) : '********'} <i className='toAnotherSmall'>Perfect Money</i></div> */}
            </div>
            <div className='toolsBox'>
              <div className={`eyeIcon ${balanceShow && 'eyeIconActive'}`} onClick={() => changeHide()} />
            </div>
          </div>
          <Link className='parentTrsBox' to='/energy'>
            <div className='trsBox'>
              <div>
                <div className='trsText'>{utils.formatter(useData.data.available.EnergyLimit - useData.data.available.EnergyUsed)}</div>
                <div className='trsTextSmall'>Available Energy</div>
              </div>
              <div className='arrowIcon'></div>
            </div>
            <div className='trsBox'>
              <div>
                <div className='trsText'>{utils.formatter(useData.data.available.NetLimit - useData.data.available.NetUsed)}</div>
                <div className='trsTextSmall'>Available Bandwidth</div>
              </div>
              <div className='arrowIcon'></div>
            </div>
          </Link>
        </div>
        {/* {['ht99tr@gmail.com', 'a@a.com', 'cryptohill@protonmail.com'].includes(useUser.profile.email) && ( */}
        <div className='airdropGet' onClick={() => dispatch(bottomDrawer(<Airdrop />))}>
          <span></span>
          <h2>Get FREE Bonus Now!</h2>
        </div>
        {/* )} */}
        {/* <div className='fastMenu'>
          <div className='actionItem' onClick={() => dispatch(bottomDrawer(listAsset('trade', 'send')))}>
            <i></i>
            <div>{t('send')}</div>
          </div>
          <div className='actionItem' onClick={() => dispatch(bottomDrawer(listAsset('trade', 'receive')))}>
            <i className='receiveIcon'></i>
            <div>{t('receive')}</div>
          </div>
          <div className='actionItem' onClick={() => dispatch(bottomDrawer(listAsset('', 'beforeSwap')))}>
            <i className='swapIcon'></i>
            <div>{t('swap')}</div>
          </div>
          <div className='actionItem' onClick={() => dispatch(bottomDrawer(voucherSelector()))}>
            <i className='voucherIcon'></i>
            <div>{t('voucher')}</div>
          </div>
        </div> */}
        {/* <div className='menuLine'></div> */}
        <div className='topSort'>
          <div className='topSortTitle'>{t('assets')}</div>
          <div className='sort' onClick={() => setUserSort('topBalance')}>
            <i className={`sortIcon ${sort === 'topBalance' && 'sortIconActive'}`}></i>
            <div className={`sortText ${sort === 'topBalance' && 'sortTextActive'}`}>{t('topBalance')}</div>
          </div>
          <div className='sort' onClick={() => setUserSort('lowBalance')}>
            <i className={`sortIcon sortIconLow ${sort === 'lowBalance' && 'sortIconActive'}`}></i>
            <div className={`sortText ${sort === 'lowBalance' && 'sortTextActive'}`}>{t('lowBalance')}</div>
          </div>
          <div className={`starIcon`}></div>
          {/* <StarIcon width={17} height={17} /> */}
        </div>
        <div className='mainBalance'>{listBalance()}</div>
      </div>
      <Bottom />
    </>
  );
};

export default Wallet;
