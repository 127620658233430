import config from 'config';
import { useQRCode } from 'next-qrcode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const Receive = ({ data }: any) => {
  const dispatch = useAppDispatch();
  const [address, setAddress] = useState('FOR GET ADDRESS, SELECT A COIN');
  const { Image } = useQRCode();
  const { t } = useTranslation();

  const copy = () => {
    navigator.clipboard.writeText(address);
    dispatch(successAlert('Copied successful'));
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/wallet`, true).post({
      coin: data?.symbol,
    });

    if (result.code === 200) {
      setAddress(result.data.address);
    } else {
      dispatch(hideDrawer());
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  };

  const again = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/wallet/again`, true).post({
      coin: data?.symbol,
    });

    if (result.code === 200) {
      setAddress(result.data.address);
    } else {
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>{t('receive')}</div>
          <div className='receiveIcon receiveIconLarge'></div>
        </div>
        <div className='pageIcon'>
          <img crossOrigin='anonymous' src={`${config.apiUrl}/${data?.icon}`} className='popupImage iconImage' />
          <div className='pageIconText'>{data?.symbol}</div>
        </div>
        <div className='spacerFlex'>
          <div className='publicCopy'>
            <span>{address}</span>
            <Image text={address} options={{ type: 'image/jpeg', quality: 1, margin: 2, width: 80 }} />
          </div>
          <div className='feeData'>
            <span>
              {t('fee')}: {data?.fee.send.type === 'percent' ? (data?.fee.receive.amount || 0) + '%' : data?.fee.receive.amount || 0}
            </span>
          </div>
          {/* <div className='blueButton againWallet' onClick={() => again()}>
            Generate New Address (2 TRX fee)
          </div> */}
        </div>
        <div className='blueButton blueButtonFull blueButtonBottom' onClick={() => copy()}>
          <i>{t('copyAddress')}</i>
        </div>
      </div>
    </div>
  );
};

export default Receive;
