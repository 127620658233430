import Express from 'components/Express';
import Login from 'components/Login';
import Register from 'components/Register';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'reducer';
import { bottomDrawer } from 'reducer/slices/drawerSlice';

const Splash = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const useData = useAppSelector((state) => state.data);

  return (
    <>
      <div className='splashContainer'>
        {/* <div className='langTop'>
          <Language />
        </div> */}
        <div className='splashInfo'>
          <div className='logo'></div>
          <span className='infoText'>{t('appText')}</span>
          <div className='fastDl'>
            <span onClick={() => dispatch(bottomDrawer(<Express />))}>{t('changeUtoU')}</span>
            <a href={`./app-${useData.data?.version?.android}.apk?v=1`} target='_blank'>
              {t('androidApplication')}
            </a>
          </div>
        </div>
        <div className='bottomSplash'>
          <div className='innerData'>
            <span>{t('secure')}</span>
            <span>{t('fast')}</span>
            <span>{t('diverse')}</span>
          </div>
          <div className='dataRegister'>
            <div className='bottomButtons'>
              <div className='regButton' onClick={() => dispatch(bottomDrawer(<Register />))}>
                <i></i>
                <span>{t('register')}</span>
              </div>
              <div className='regButton singButton' onClick={() => dispatch(bottomDrawer(<Login />))}>
                <i></i>
                <span>{t('signin')}</span>
              </div>
            </div>
            <a href={`./app-${useData.data?.version?.android}.apk?v=1`} target='_blank' className='wonFast'>
              {t('needApp')}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Splash;
