const formatter = (price: number) => {
  const data = price.toString().split('.');
  return data[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (data[1] ? '.' + data[1] : '');
}

const toFixed = (number: number, value: number = 8) => {
  return Number((number).toFixed(value));
}

const toFloor = (number: number, value: number = 8) => {
  const precision = Math.pow(10, value)
  return Math.floor(number * precision) / precision;
}

const toCeil = (number: number, value: number = 8) => {
  const precision = Math.pow(10, value)
  return Math.ceil(number * precision) / precision;
}

export default {
  formatter,
  toFixed,
}
