import config from 'config';
import Bottom from 'layouts/Bottom';
import Header from 'layouts/Header';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';
import utils from 'tools/utils';

const Swap = () => {
  const dispatch = useAppDispatch();
  const useData = useAppSelector((state) => state.data);
  const useUser = useAppSelector((state) => state.user);
  const [from, setFrom] = useState<any>();
  const [to, setTo] = useState<any>();
  const { t } = useTranslation();
  const [amount, setAmount] = useState('');

  let rate = from?.swap.find((e: any) => e.coin === to?.symbol)?.percent || 0;
  let receive = utils.toFixed(Number(amount) * (rate / 100 + 1) || 0, 9);

  const trxPrice = useData.market.find((e: any) => e.symbol === 'TRX')?.price;
  if (from?.symbol === 'TRX') {
    rate = useData.assets.find((e: any) => e.symbol === 'USDT')?.swap.find((e: any) => e.coin === to?.symbol)?.percent || 0;
    receive = utils.toFixed(Number(amount) * trxPrice * (rate / 100 + 1) || 0, 2);
  }

  if (to?.symbol === 'TRX') {
    rate = from?.swap.find((e: any) => e.coin === 'USDT')?.percent || 0;
    receive = utils.toFixed((Number(amount) / trxPrice) * (rate / 100 + 1) || 0, 2);
  }

  if (receive < 0) {
    receive = 0;
  }

  const swapConfirm = async () => {
    if (amount < from?.limit?.send?.min || amount > from?.limit?.send?.max) {
      dispatch(errorAlert('Amount is out of range'));
      return;
    }

    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/wallet/swap`, true).post({
      coin: from?.symbol,
      to: to?.symbol,
      amount: amount,
    });

    if (result.code === 200) {
      dispatch(hideDrawer());
      dispatch(successAlert('successSwap'));
    } else {
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  };

  const swapCrypto = async () => {
    const confirmAction = window.confirm(`You want to swap ${amount} ${from.symbol} to ${receive} ${to.symbol}. Are you sure?`);
    if (confirmAction) {
      swapConfirm();
    }
  };

  const fromList = (val: string) => {
    let filter = useData.assets;

    if (val === 'from') {
      filter = filter.filter((e: any) => e.swap.length);
    } else if (val === 'to' && from) {
      const beforeMap = from.swap.map((e: any) => e.coin);
      filter = filter.filter((e: any) => beforeMap.includes(e.symbol));
    }

    const rows: any = [];

    filter.forEach((e: any, i: number) => {
      rows.push(
        <div
          key={i}
          className='assetSelect assetBalance'
          onClick={() => (val === 'from' ? setFrom(e) : from && setTo(e))}
          style={from && from !== e && val === 'from' ? { opacity: 0.2 } : {}}
        >
          <img crossOrigin='anonymous' src={`${config.apiUrl}/${e?.icon}`} className='popupImage balanceImage' />
          <div className='walletLeft'>
            <div className='walletText'>{e.symbol}</div>
            <div className='walletText walletTextName'>{e.name}</div>
          </div>
        </div>,
      );
    });

    return rows;
  };

  return (
    <>
      <Header title='Swap Coins' />
      <div className='menuLine'></div>
      {from && to ? (
        <div className='marketList swapFrom'>
          <section>
            <div
              className='backParent'
              onClick={() => {
                setFrom(undefined);
                setTo(undefined);
                setAmount('');
              }}
            >
              <div className='backIcon'></div>
            </div>
            <div className='pageIcon'>
              <img crossOrigin='anonymous' src={`${config.apiUrl}/${from?.icon}`} className='popupImage iconImage' />
              <div className='pageIconText'>{from?.symbol}</div>
              <i> </i>
              <img crossOrigin='anonymous' src={`${config.apiUrl}/${to?.icon}`} className='popupImage iconImage' />
              <div className='pageIconText'>{to?.symbol}</div>
            </div>
            <div className='form'>
              <div className='inputParent'>
                <span>{t('amount')}</span>
                <input type='text' placeholder='Amount' onChange={(e) => setAmount(e.target.value)} />
              </div>
            </div>
            <div className='limitData'>
              <span>
                {t('minAmount')}: {from?.limit?.send?.min || 0}
              </span>
              <span>
                {t('maxAmount')}: {from?.limit?.send?.max || 0}
              </span>
            </div>
            <div className='feeData'>
              <span>
                {t('receive')}: {utils.formatter(receive)} {to.symbol}
              </span>
              <span>
                {t('rate')}: {utils.formatter(rate)}% {(from?.symbol === 'TRX' || to?.symbol === 'TRX') && <> - TRX: {utils.toFixed(trxPrice, 4)}</>}
              </span>
            </div>
            <div className='blueButton blueButtonFull blueButtonBottom' onClick={() => swapCrypto()}>
              <i>{t('swap')}</i>
            </div>
          </section>
        </div>
      ) : (
        <div className='marketList swapList'>
          <section>
            <h1>Select Coin From</h1>
            {fromList('from')}
          </section>
          <section style={!from ? { opacity: 0.2 } : {}}>
            <h1>Select Coin To</h1>
            {fromList('to')}
          </section>
        </div>
      )}
      <Bottom />
    </>
  );
};

export default Swap;
