import config from 'config';
import Bottom from 'layouts/Bottom';
import Header from 'layouts/Header';
import { useState } from 'react';
import { useAppSelector } from 'reducer';
import utils from 'tools/utils';

const Market = () => {
  const useData = useAppSelector(state => state.data)
  const [search, setSearch] = useState('');

  const filter = useData.market
    .filter((e: any) => {
      return !useData.assets.map((e: any) => e.symbol).includes(e.symbol) && e.symbol.toLowerCase().includes(search.toLowerCase());
    })
    .sort((a: any, b: any) => a.rank - b.rank);

  const listMarket = () => {
    const rows: any = [];

    filter.forEach((data: any, i: number) => {
      rows.push(
        <div key={i}>
          <img crossOrigin='anonymous' src={`${config.apiUrl}/icons/${data.symbol.toLowerCase()}.png`} />
          <div className='marketView'>
            <span>{data.symbol}</span>
            <span>{data.name}</span>
          </div>
          <div className='marketView'>
            <span>{utils.formatter(data.price)}$</span>
            <span>{utils.formatter(data.toman)} IRR</span>
          </div>
          <span className={`marketPercent ${data.percent < 0 && 'marketPriceRed'}`}>{data.percent}%</span>
        </div>
      )
    });

    return rows;
  }

  return (
    <>
      <Header title='Crypto Market' />
      <div className='menuLine'></div>
        {/* <div className='search'>
          <i></i>
          <input placeholder='Search...' onChange={(e) => setSearch(e.target.value)} />
        </div> */}
        <div className='marketList'>
          {listMarket()}
        </div>
      <Bottom />
    </>
  );
}

export default Market;
