import config from 'config';
import Bottom from 'layouts/Bottom';
import Header from 'layouts/Header';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'reducer';
import { bottomDrawer, hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';
import Receive from './Receive';
import Send from './Send';

const Fast = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const location = useLocation();
  const data = location.state;

  const showList = () => {
    const rows: any = [];

    list.forEach((e: any, i) => {
      rows.push(
        <div className='showToken showTrans showVoucher' key={i}>
          <img crossOrigin='anonymous' src={`${config.apiUrl}/icons/${e.coin.toLowerCase()}.png`} />
          <div>
            <span>
              {t(e.side)} {e.amount} {e.coin} {e.side === 'receive' ? 'Form ' : 'To '} {e.data.receiver}
            </span>
            {/* <span>ID: {t(e.id)}</span> */}
            <span>
              {t('status')}: {t(e.status)}
            </span>
            {/* {(e?.data?.hash || e?.data?.reference) && (
              <span>
                {t('hash')}: {e?.data?.hash || e?.data?.reference}
              </span>
            )} */}
            <span>
              {t('date')}: {moment(e.createdAt).format('MMM D, YYYY, HH:mm:ss')}
            </span>
          </div>
        </div>,
      );
    });

    if (rows.length === 0) {
      rows.push(<div key={1000000}>{t('listEmpty')}</div>);
    }

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/transaction?type=fast`, true).get();
    if (result.code === 200) {
      setList(result.data);
    } else {
      dispatch(hideDrawer());
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    if (data?.type === 'send') {
      setTimeout(() => {
        dispatch(bottomDrawer(<Send data={data.coin} />));
      }, 300);
    } else {
      init();
    }
  }, [data]);

  return (
    <>
      <Header title={'Fast'} />
      <div className='menuLine'></div>
      <div className='splashContainer'>
        <div className='direct' style={{ flex: 'none' }}>
          {/* <i>{t('fastInfo')}</i> */}
          <div className='xbet'></div>
        </div>

        <div className='fastMenu fastMenuFast' style={{ flex: 'none' }}>
          <div className='actionItem' onClick={() => dispatch(bottomDrawer(<Send />))}>
            <i></i>
            <div>{t('send')}</div>
          </div>
          <div className='actionItem' onClick={() => dispatch(bottomDrawer(<Receive />))}>
            <i className='receiveIcon'></i>
            <div>{t('receive')}</div>
          </div>
        </div>
        <div className='form list listUp' style={{ marginBottom: '95px' }}>
          {showList()}
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default Fast;
