import Cookies from 'js-cookie';

interface IHeaders {
  'content-type'?: string;
  authorization?: string;
}

interface IResponse {
  favorites: string[];
  message: string;
  data: any;
  code: number;
}

const getContent = async (url: string, isAuth: boolean, method: string, headers: IHeaders, body: any): Promise<IResponse> => {
  // add accessToken for auth
  if (isAuth) {
    headers['authorization'] = Cookies.get('accessToken');
  }

  // add contentType for json if it is empty
  if (!headers['content-type']) {
    headers['content-type'] = 'application/json';
  }

  if (headers['content-type'] === 'form-data') {
    delete headers['content-type'];
  }

  // convert form body to JSON if contentType is json
  if (headers['content-type'] === 'application/json' && body) {
    body = JSON.stringify(body);
  }

  const headersInit: HeadersInit = new Headers();
  Object.entries(headers).forEach(([k, v]) => {
    headersInit.set(k, v);
  });
  
  return await fetch(url, {
    method: method,
    headers: headersInit,
    body: (body !== '{}') ? body : undefined,
  })
  .then(async (response) => {
    const res = await response.json();
    res.code = response.status;

    if ((res.code === 401 || res.message === 401) && headers['authorization'] && headers['authorization'] !== 'Bearer null') {
      Cookies.set('refreshToken', '');
      Cookies.set('accessToken', '');
      window.location.reload();
      return;
    }

    return res;
  })
  .catch(error => {
    console.log('Fetch error', error);
    return {};
  });
}

const gather = (url: string, isAuth: boolean = false) => {
  const get = async (headers: IHeaders = {}) => {
    return getContent(url, isAuth, 'GET', headers, {});
  }

  const post = async (body: {} = {}, headers: IHeaders = {}) => {
    return getContent(url, isAuth, 'POST', headers, body);
  }

  const del = async (body: {} = {}, headers: IHeaders = {}) => {
    return getContent(url, isAuth, 'DELETE', headers, body);
  }

  const put = async (body: {} = {}, headers: IHeaders = {}) => {
    return getContent(url, isAuth, 'PUT', headers, body);
  }

  const upload = async (body: {} = {}, headers: IHeaders = {}) => {
    if (!headers['content-type']) {
      headers['content-type'] = 'form-data';
    }

    return getContent(url, isAuth, 'POST', headers, body);
  }

  return {
    delete: del,
    get,
    post,
    put,
    upload,
  }
}

export default gather;
