import App from 'App';
import 'assets/css/main.css';
import 'assets/css/rtl.css';
import 'lang';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'reducer/store';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

reportWebVitals();
