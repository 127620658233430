import config from 'config';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { bottomDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { getProfile } from 'reducer/slices/userSlice';
import gather from 'tools/gather';
import TwoStep from './TwoStep';

const Setting = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const useData = useAppSelector(state => state.data)
  const useUser = useAppSelector(state => state.user)

  const confirm = async () => {
    dispatch(showLoading());
    
    const result = await gather(`${config.apiUrl}/v1/user/twoStep`, true).delete();
    if (result.code === 200) {
      await dispatch(getProfile());
      dispatch(successAlert('Two step verification deactivated successful'));
    } else {
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  }

  const openTwoStep = async () => {
    if (useUser.profile?.twoStep?.type) {
      const confirmAction = window.confirm('Are you sure you want to deactive two step verfication?');
      if (confirmAction) {
        confirm()
      }
    } else {
      dispatch(bottomDrawer(<TwoStep />));
    }
  }

  return (
    <>
      <Link className='backParent' to={'/'}>
        <div className='backIcon'></div>
      </Link>
      <div className='pageTop'>
        <div className='pageTopTitle'>{t('setting')}</div>
        <div className='settingIcon'></div>
      </div>
      <div className='parentSetting'>
        <h4 className='settingTitle'>{t('security')}</h4>
        <div className='settingLiner' onClick={() => openTwoStep()}>
          <span className={`checkbox ${useUser.profile?.twoStep?.type && 'checkboxActive'}`}></span>
          <i>{t('twoStep')}</i>
        </div>
        {/* <br />
        <h4 className='settingTitle'>{t('language')}</h4>
        <div className='settingLiner langTop langTopSetting'>
          <Language />
        </div> */}
      </div>
    </>
  );
}

export default Setting;
