import config from 'config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const example = {
  'PM': 'XXXXXXXXXX-YYYYYYYYYYYYYYYY',
  'PME': 'XXXXXXXXXX-YYYYYYYYYYYYYYYY',
  'CV': 'XXXXXXXXXXX-YYYYYYYYYYYYYYYYY',
  'UUSD': 'USD-XXXX-XXXX-XXXX-XXXX-XXXX',
} as any;

const UseVoucher = ({ data }: any) => {
  const dispatch = useAppDispatch();
  const [code, setCode] = useState('');
  const { t } = useTranslation();

  const activate = async () => {
    dispatch(showLoading());
    
    const result = await gather(`${config.apiUrl}/v1/voucher/use`, true).post({
      coin: data?.symbol,
      code: code,
    });
    
    if (result.code === 200) {
      if (result.data.receive > 0) {
        dispatch(successAlert('You received: ' + result.data.receive + ' ' + data?.symbol));
      } else {
        dispatch(successAlert('Your voucher added, Please wait until confirm voucher'));
      }

      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  }

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>{t('useVoucher')}</div>
          <div className='receiveIcon receiveIconLarge useIcon'></div>
        </div>
        <div className='pageIcon'>
          <img crossOrigin='anonymous' src={`${config.apiUrl}/${data?.icon}`} className='popupImage iconImage' />
          <div className='pageIconText'>{data?.symbol}</div>
        </div>
        <div className='spacerFlex'>
          <div className='form'>
            <div className='inputParent'>
              <span>{t('code')}</span>
              <input type='text' placeholder={example[data.symbol] ?? 'Voucher code'}onChange={(e) => setCode(e.target.value)} />
            </div>
          </div>
          <div className='feeData'>
            <span>{t('fee')}: {data?.fee.receive.type === 'percent' ? (data?.fee.receive.amount || 0) + '%' : (data?.fee.receive.amount || 0) + '$'}</span>
          </div>
        </div>
        <div className='blueButton blueButtonFull blueButtonBottom' onClick={() => activate() }><i>{t('activate')}</i></div>
      </div>
    </div>
  );
}

export default UseVoucher;
