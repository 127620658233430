import config from 'config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';
import utils from 'tools/utils';

const currencyTitle = {
  usd: 'USD',
  irr: 'IRR',
};

const Send = ({ data }: any) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [receiver, setReceiver] = useState('');
  const [amount, setAmount] = useState('');
  const [coin, setCoin] = useState(data ?? '');
  const [currency, setCurrency] = useState('');
  const useData = useAppSelector((state) => state.data);
  const useUser = useAppSelector((state) => state.user);

  const balance = useUser.wallet.find((e: any) => e.coin === coin)?.free || 0;
  const coinPrice = useData.market.find((e: any) => e.symbol === coin)?.price || 0;
  const coinToman = useData.market.find((e: any) => e.symbol === coin)?.toman || 0;

  const selectedString = coin || 'Your selected coin';

  const isActiveTab = () => {
    const rows: any = [];

    Object.entries(currencyTitle).forEach(([k, v]) => {
      rows.push(
        <span className={currency === v ? 'inActiveTabs' : ''} onClick={() => setCurrency(v)} key={k}>
          {v}
        </span>,
      );
    });

    return rows;
  };

  const coinTabOption = () => {
    const rows: any = [];

    useData.assets.forEach((v: any, k: number) => {
      if (v.symbol === 'TRX' || v.symbol === 'USDT' || v.symbol === 'UUSD') {
        rows.push(<option key={k}>{v.symbol}</option>);
      }
    });

    return rows;
  };

  const sendCrypto = async () => {
    const confirmAction = window.confirm(`You want to send ${amount} to ${receiver}. Are you sure?`);
    if (confirmAction) {
      confirmCrypto();
    }
  };

  const confirmCrypto = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/cash/send`, true).post({
      amount: amount,
      receiver: receiver,
      coin: coin,
      // currency: currency,
    });

    if (result.code === 200) {
      dispatch(hideDrawer());
      dispatch(successAlert('successSend'));
    } else {
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>{t('send1XBet')}</div>
          <div className='receiveIcon sendIcon receiveIconLarge'></div>
        </div>
        <div className='spacerFlex'>
          {/* <div className='tabs'>
            {isActiveTab()}
          </div> */}
          {/* {!currency ? (
            <div className='direct'><br/><br/>Please selet your 1xbet account currency</div>
          ) : ( */}
          <>
            <div className='form formBottom'>
              <div className='inputParent'>
                <span>Coin</span>
                <div className='selectBox'>
                  <select defaultValue={coin} onChange={(e) => setCoin(e.target.value)}>
                    <option value={''}>Select coin</option>
                    {coinTabOption()}
                  </select>
                </div>
              </div>
              {coin && (
                <div className='feeData'>
                  <span>
                    {t('yourBalance')}: {utils.formatter(balance)} {coin}
                  </span>
                </div>
              )}
              <div className='inputParent'>
                <span>{t('amount')}</span>
                <input type='text' placeholder={`Amount (${selectedString})`} onChange={(e) => setAmount(e.target.value)} />
              </div>
              <div className='inputParent'>
                <span>{t('receiverID')}</span>
                <input type='text' placeholder='Receiver ID' onChange={(e) => setReceiver(e.target.value)} />
              </div>
            </div>
            {/* {coin && 
                <div className='limitData limitDataLeft'>
                  {currency === 'IRR' && (
                    <span>1 USD = {utils.formatter(usdtToman)} IRR</span>
                  )}
                  <span>1 {coin} = {utils.toFixed(rate, 4)} {coin === 'TRX' ? 'USDT' : 'cVoucher'}</span>
                </div>
              } */}
            <div className='feeData'>
              <span>USD: {utils.formatter(utils.toFixed(Number(amount) * coinPrice, 3))}</span>
              <span>IRR: {utils.formatter(utils.toFixed(Number(amount) * coinToman, 0))}</span>
            </div>
          </>
          {/* )} */}
        </div>
        <div className='blueButton blueButtonFull blueButtonBottom' onClick={() => sendCrypto()}>
          <i>{t('send')}</i>
        </div>
      </div>
    </div>
  );
};

export default Send;
