import { useAppDispatch } from 'reducer';
import { hideDrawer } from 'reducer/slices/drawerSlice';

const Contact = () => {
  const dispatch = useAppDispatch();
  
  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>Contact Us</div>
          {/* <div className='receiveIcon receiveIconLarge'></div> */}
        </div>
        <div className='spacerFlex'>
      <div className='form list'>
        <div className='showToken'>
          <div>
            <span>Email:</span>
            <span>iran@u.is</span>
          </div>
        </div>
        <div className='showToken'>
          <div>
            <span>Utopia:</span>
            <span>UNKNOWN13</span>
          </div>
          <div className='ucodeParent'>
            <span className='ucode'></span>
          </div>
        </div>
      </div>
      </div>
      </div>
    </div>
  );
}

export default Contact;
