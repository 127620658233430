import config from 'config';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'reducer';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { getProfile } from 'reducer/slices/userSlice';
import gather from 'tools/gather';
import utils from 'tools/utils';

let need = 0;

const Airdrop = () => {
  const dispatch = useAppDispatch();
  const useUser = useAppSelector((state) => state.user);
  const [current, setCurrent] = useState(useUser.profile.airdrop.current);
  const [achieve, setAchieve] = useState(useUser.profile.airdrop.achieve);
  const [array, setArray] = useState<{ x: number; y: number }[]>([]);

  const taptap = (e: any) => {
    if (current <= 0) {
      return;
    }

    setCurrent(current - 1);
    setAchieve(achieve + 1);
    setArray((array) => [...array, { x: e.clientX, y: e.clientY }]);

    need += 1;
  };

  const listShow = () => {
    const rows: any = [];

    array.forEach((e, i) => {
      rows.push(
        <i key={i} style={{ left: e.x, top: e.y - 40 }}>
          1
        </i>,
      );
    });

    return rows;
  };

  const init = () => {
    if (need > 0) {
      gather(`${config.apiUrl}/v1/airdrop`, true).post({
        value: need,
      });

      need = 0;
    } else {
      dispatch(getProfile());
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      init();
    }, 2000);

    return () => {
      dispatch(getProfile());
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setCurrent(useUser.profile.airdrop.current);
  }, [useUser.profile.airdrop.current]);

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>Free Bonus</div>
          <div className='receiveIcon bonusIcon receiveIconLarge'></div>
        </div>
        <div className='spacerFlex airFlex'>
          <p className='currentAir'>Your Total Bonus: {utils.formatter(achieve)}</p>
          <p className='infoAir'>
            Tap Tap on Diamond and get FREE bonus!
            <br />
            Available: {utils.formatter(current)}
          </p>
          <div className='uwalletClicker' onClick={(e) => taptap(e)}>
            {listShow()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Airdrop;
