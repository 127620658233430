const config = {
  apiUrl: process.env.REACT_APP_API_URL!,
  errorCode: {
    code_10001: 'This email already exists',
    code_10002: 'Email is not valid',
    code_10003: 'Password is empty',
    code_10004: 'Repassword is empty',
    code_10009: 'Code is not valid',
    code_10010: 'Code is wrong',
    code_10011: 'Email or your password is wrong',
    code_10012: 'Name is empty',
    code_10018: 'Amount is not numberic',
    code_10019: 'Coin is not exists',
    code_10022: 'Your balance is low',
    code_10024: 'Receiver address is not valid',
    code_10026: 'This name already exists',
    code_10027: 'Your are not allow create token',
    code_10028: 'This code has already been used',
    code_10032: 'This username is not register in BOT',
    code_500: 'Internal server error, try again',
    code_502: 'Gateway error, try again',
    code_503: 'Service not available',
    code_404: 'Not found your request',
    code_401: "You can't access this route",
    errorFound: 'Unknown Error! try again',
  } as any,
};

export default config;
