import TokenList from 'components/TokenList';
import config from 'config';
import { useState } from 'react';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { bottomDrawer, hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const CreateToken = () => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState('');
  const [callback, setCallback] = useState('');

  const create = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/token`, true).post({
      name: name,
      callback: callback,
    });

    if (result.code === 200) {
      dispatch(hideDrawer());

      setTimeout(() => {
        dispatch(bottomDrawer(<TokenList />));
      }, 300);

      dispatch(successAlert('Token created successful'));
    } else {
      dispatch(errorAlert(config.errorCode['code_' + result?.message] ?? config.errorCode['errorFound']));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>Create Token</div>
          {/* <div className='receiveIcon receiveIconLarge sendIcon'></div> */}
        </div>
        <div className='spacerFlex'>
          <div className='form'>
            <div className='inputParent'>
              <span>Token Name</span>
              <input type='text' placeholder='Name' onChange={(e) => setName(e.target.value)} />
            </div>
            <div className='inputParent'>
              <span>Callback URL (optional)</span>
              <input type='text' placeholder='URL' onChange={(e) => setCallback(e.target.value)} />
            </div>
          </div>
        </div>
        <span className='blueButton blueButtonFull blueButtonBottom' onClick={() => create()}>
          <i>Create</i>
        </span>
      </div>
    </div>
  );
};

export default CreateToken;
